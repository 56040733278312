import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, map } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ApiCollectionResponse } from '../../shared/models/api-collection-response.model';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Injectable()
export class CollectionParserInterceptor implements HttpInterceptor {

  constructor(private router: Router, private messageService: MessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const excludedUrl = '/audit/traceability';
    if (request.url.includes(excludedUrl)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            
            if(event.status === 200 && event.body['@type'] === 'hydra:Collection'){
                event = event.clone({body: event.body.data});

                const pageExpression = /page=(\d+)/i
                // Create apiCollectionResponseResponse object
                let apiCollectionResponse = new ApiCollectionResponse();

                // Get totalItems
                apiCollectionResponse.totalItems = event.body['hydra:totalItems'];

                // Get items
                if(apiCollectionResponse.totalItems && apiCollectionResponse.totalItems > 0) {
                    event.body['hydra:member'].forEach((item:any) => {
                        apiCollectionResponse.items.push(item);
                    });
                }

                // Get pages information
                if(event.body['hydra:view']) {
                    let match = pageExpression.exec(event.body['hydra:view']['hydra:first']);
                    if(match && match[1]) apiCollectionResponse.firstPage = +match[1];
    
                    // Get last page
                    match = pageExpression.exec(event.body['hydra:view']['hydra:last']);
                    if(match && match[1]) apiCollectionResponse.lastPage = +match[1];
    
                    // Get previous page
                    match = pageExpression.exec(event.body['hydra:view']['hydra:previous']);
                    if(match && match[1]) apiCollectionResponse.previousPage = +match[1];
    
                    // Get next page
                    match = pageExpression.exec(event.body['hydra:view']['hydra:next']);
                    if(match && match[1]) apiCollectionResponse.nextPage = +match[1];
                }                

                // Clone new body structure
                event = event.clone({ body: apiCollectionResponse });

            }
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if(environment.production) {
          this.router.navigate(['/']);
        }
        return throwError(() => error.error);
      })
    );
  }
}
